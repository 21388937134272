import { graphql, Link } from "gatsby";
import React from "react";
import { H1 } from "../../components/H1";
import { Layout } from "../../components/Layout";

export default function Thoughts({ data }) {
  const {
    allMarkdownRemark: { edges },
  } = data;
  const posts = edges.map(({ node }) => node.frontmatter);

  return (
    <Layout pageTitle="Beer's thoughts">
      <H1>
        <span role="img" aria-label="thinking face">
          🤔
        </span>{" "}
        My Thoughts
      </H1>
      <div className="p-8">
        {posts.map((post, index) => (
          <div key={index} className="mb-4">
            <p className="text-lg">
              <Link to={post.slug} className="underline">
                {post.title}
              </Link> - <span className="text-copy-lightgray text-base">{post.date}</span>
            </p>
            <p className="pl-4">{post.short_description}</p>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { listed: { eq: true }, published: { eq: true } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            short_description
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
